:root {
  overflow: hidden;
}

body {
  user-select: none;
}

input,
textarea {
  user-select: text;
}
